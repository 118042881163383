/**
 * Created by Alenych on 28.08.2015.
 */

$.ajaxSetup({
  headers: {
    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
  }
});

$(function() {

  $('.input-daterange').datepicker({
    maxViewMode: 1,
    format: "dd-mm-yyyy",
    weekStart: 1,
    daysOfWeekDisabled: "1,2,3,4",
    autoclose: true,
    startDate: "0d",
    todayHighlight: false
  });


  $('#form_submit').on('click', function(){
   $('.error').hide();
    $('.success').hide();

   var response = $('#g-recaptcha-response').val();
   var data = {
    name: $('#name').val(),
    email: $('#email').val(),
    phone: $('#phone').val(),
    weight: $('#weight').val(),
    date: $('#date').val(),
    card: $('#card').val(),
    info: $('#info').val(),
    lang: $('#lang').val()
  };

    $.post( '/home/registration', { response : response, data: data}).done(function(data){
      var result = $.parseJSON(data);
      if(isDefined(result.status)){
        if(result.status == false){
          $('#recaptha_error').show();
        }else{
          $('#success').show();
          $('.empty-form').val('');
        }
      }else{
        for (var k in result) {
          $('#'+k+'_error').show();
        }
      }

    });
    grecaptcha.reset();
  });

  $('#gift_submit').on('click', function(){
    $('.error').hide();
    $('.success').hide();
    var data = {
      email: $('#email_gift').val(),
      type: $('#gift_type').val(),
      lang: $('#lang_gift').val()
    }

    $.post( '/home/gift', { data: data}).done(function(data){
      var result = $.parseJSON(data);
      if(isDefined(result.status)){
          $('#gift_success').show();
		  $('#email_gift').val('');
      }else{
          $('#email_gift_error').show();
      }

    });

  });

});


function isDefined(v) {
  return !!((typeof v !== 'undefined' && v !== null));
}
